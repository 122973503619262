import React, { useState } from "react"

import {
  filterOptimistic,
  getTimeNow,
  orderByDate,
  zeroMinutes,
} from "../../../utils"
import { availabilitySlots, availableDurations } from "../utils"

import { useAppSelector } from "../../../redux/store"

import Button from "../../../components/Button"
import GridSelect from "../../../components/GridSelect"

import CheckSVG from "../../../assets/icons/check.svg"

type Props = {
  slot?: number | null
  duration?: number | null
  onChangeTimeslot: (timeslot: {
    slot?: number | null
    duration?: number | null
  }) => void
  onOpenInitialForm: () => void
}

const TimeslotForm = ({
  slot,
  duration,
  onChangeTimeslot,
  onOpenInitialForm,
}: Props) => {
  const [slotCount, setSlotCount] = useState(23)
  const [areSlotsExpanded, setAreSlotsExpanded] = useState(false)

  const events = useAppSelector((state) =>
    orderByDate(filterOptimistic(state.events.events)),
  )

  const now = getTimeNow()
  const expiredSlots = Math.floor(now.minute() / 15)

  const currentSlot = zeroMinutes(now)
    .add(expiredSlots * 15, "minute")
    .valueOf()

  const slots = availabilitySlots(
    events,
    now,
    currentSlot,
    slotCount,
    expiredSlots,
  )
  const durations = availableDurations(events, slot, 4)

  return (
    <>
      <div className="form">
        <div>
          <div className="sub-title">Start time</div>
          <GridSelect
            items={slots}
            value={slot ?? null}
            onChange={(value) => onChangeTimeslot({ slot: value, duration })}
            onExpand={() => {
              setSlotCount((prev) => prev + 32)
              setAreSlotsExpanded(true)
            }}
            isExpanded={areSlotsExpanded}
            showExpander
          />
        </div>

        <div>
          <div className="sub-title">Duration</div>
          <GridSelect
            items={durations}
            value={duration ?? null}
            onChange={(value) => onChangeTimeslot({ slot, duration: value })}
            isExpanded={true}
            isHorizontal
          />
        </div>
      </div>

      <div className="confirm-actions">
        <Button className="Confirm" onClick={onOpenInitialForm} isIconButton>
          <CheckSVG />
        </Button>
      </div>
    </>
  )
}

export default TimeslotForm
