import React from "react"

import { Dayjs } from "dayjs"

import { ISODate } from "../../types/sharedTypes"
import { formatTimeWithNumerals, getTime } from "@joan/joan-core"

type Props = {
  className?: string
  date: ISODate | Dayjs
  isInline?: boolean
  isLongDay?: boolean
}

const Datestring = ({
  className,
  date,
  isInline,
  isLongDay,
  ...props
}: Props) => {
  const DATE = typeof date === "string" ? getTime(date) : date

  const dayFormat = isLongDay ? "dddd" : "ddd"
  const format = isInline ? `${dayFormat}, Do MMM` : `${dayFormat} Do MMM`
  const formatedDate = formatTimeWithNumerals(DATE, format)

  return (
    <span className={className} {...props}>
      {formatedDate}
    </span>
  )
}

export default Datestring
