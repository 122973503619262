import React, { HTMLProps } from "react"

import classNames from "classnames"

import LoaderSVG from "../../assets/icons/loader.svg"

import "./style.sass"

const VARIANT_SIZES = [
  "x-small",
  "small",
  "medium",
  "large",
  "x-large",
] as const
const THEMES = ["dark", "light"] as const

type Variant = (typeof VARIANT_SIZES)[number]
type Theme = (typeof THEMES)[number]

interface Props extends HTMLProps<HTMLDivElement> {
  variant?: Variant
  theme?: Theme
}

const Loader = ({ variant = "medium", theme = "light", ...props }: Props) => {
  const className = classNames(
    "loader",
    `loader--${variant}`,
    `loader--${theme}`,
  )

  return (
    <div className={className} {...props}>
      <LoaderSVG />
    </div>
  )
}

export default Loader
