import React, { useCallback, useState } from "react"

import { useHistory } from "react-router-dom"

import { IS_DEMO_MODE, PATHS } from "../../constants"
import {
  eventConflicts,
  filterOptimistic,
  getTimeNow,
  orderByDate,
  zeroMinutes,
} from "../../utils"
import { availabilitySlots, availableDurations } from "../MeetLater/utils"
import { Trans } from "@joan/joan-core"

import { meetNowDemo } from "../../redux/demoActions"
import { meetNow } from "../../redux/events/actions"
import { interfaceConfigSelector } from "../../redux/selectors"
import { useAppDispatch, useAppSelector } from "../../redux/store"

import ConfirmActions from "../../components/ConfirmActions"
import GridSelect from "../../components/GridSelect"
import NavHeaderBar from "../../components/NavHeaderBar"

import "./style.sass"

const MeetNow = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const { config, isMeetNowLoading } = useAppSelector((state) => ({
    config: interfaceConfigSelector(state.constants),
    isMeetNowLoading: state.meetNow.isLoading,
  }))

  const [slotValue, setSlotValue] = useState<number | null>(null)
  const [durationValue, setDurationValue] = useState<number | null>(null)
  const [areDurationsExpanded, setAreDurationsExpanded] = useState(true)

  const events = useAppSelector((state) =>
    orderByDate(filterOptimistic(state.events.events)),
  )

  const now = getTimeNow()
  const expiredSlots = Math.floor(now.minute() / 15)

  const currentSlot = zeroMinutes(now)
    .add(expiredSlots * 15, "minute")
    .valueOf()

  const slots = availabilitySlots(events, now, currentSlot, 1, expiredSlots)

  const durations = availableDurations(
    events,
    slotValue,
    areDurationsExpanded ? 5 : 3,
  )

  const nextAvailableSlot = slots.find(({ value, isDisabled }) => {
    const doesConflict = eventConflicts(events, value)
    return !doesConflict && !isDisabled
  })

  const initialSlot = eventConflicts(events, now)
    ? nextAvailableSlot
      ? nextAvailableSlot.value
      : null
    : currentSlot

  const initialDuration = durations[0]?.value ?? config.meetNowDuration
  const selectedSlot = slotValue || initialSlot
  const selectedDuration = durationValue || initialDuration

  const handleConfirmClick = useCallback(async () => {
    const response = await (IS_DEMO_MODE
      ? dispatch(meetNowDemo(selectedDuration))
      : dispatch(meetNow(selectedDuration)))

    if (response?.ok) {
      history.push(PATHS.HOME)
    }
  }, [dispatch, history, selectedDuration])

  const isDisabled = !selectedSlot && !selectedDuration

  return (
    <div className="MeetNow Route">
      <NavHeaderBar title={<Trans>Meet now</Trans>} isShowCloseButton />

      <div className="form">
        <div>
          <div className="sub-title">
            <Trans>Selected time slot</Trans>
          </div>
          <GridSelect
            items={slots}
            value={selectedSlot}
            onChange={setSlotValue}
          />
        </div>

        <div>
          <div className="sub-title">
            <Trans>Duration</Trans>
          </div>
          <GridSelect
            items={durations}
            value={selectedDuration}
            onChange={setDurationValue}
            onExpand={() => setAreDurationsExpanded(true)}
            isExpanded={areDurationsExpanded}
            showExpander={!areDurationsExpanded}
            isHorizontal
          />
        </div>
      </div>

      <ConfirmActions
        isAutoSubmitConfirm
        onSubmitConfirm={handleConfirmClick}
        isDisabled={isDisabled}
        isSubmitting={isMeetNowLoading}
      />
    </div>
  )
}

export default MeetNow
