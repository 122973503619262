import { useEffect, useState } from "react"

type Orientation = "landscape" | "portrait"

const getOrientation = (): Orientation =>
  window.innerWidth > window.innerHeight ? "landscape" : "portrait"

const useOrientation = () => {
  const [orientation, setOrientation] = useState<Orientation>(getOrientation())

  const handleResize = () => {
    setOrientation(getOrientation())
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return {
    isPortrait: orientation === "portrait",
    isLandscape: orientation === "landscape",
  }
}

export default useOrientation
