import { useEffect, useState } from "react"

import { Capacitor, PluginListenerHandle } from "@capacitor/core"
import { Network } from "@capacitor/network"

export const useNetworkStatus = () => {
  const [isOnline, setOnline] = useState<boolean>(navigator.onLine)

  useEffect(() => {
    const handleOnline = () => setOnline(true)
    const handleOffline = () => setOnline(false)

    if (Capacitor.isNativePlatform()) {
      const checkNetworkStatus = async () => {
        const status = await Network.getStatus()
        setOnline(status.connected)
      }

      let networkListener: PluginListenerHandle | undefined

      const setupNetworkListener = async () => {
        networkListener = await Network.addListener(
          "networkStatusChange",
          (status) => {
            setOnline(status.connected)
          },
        )
      }

      checkNetworkStatus()
      setupNetworkListener()

      return () => {
        if (networkListener) {
          networkListener.remove()
        }
      }
    } else {
      window.addEventListener("online", handleOnline)
      window.addEventListener("offline", handleOffline)

      return () => {
        window.removeEventListener("online", handleOnline)
        window.removeEventListener("offline", handleOffline)
      }
    }
  }, [])

  return { isOnline, isOffline: !isOnline }
}
