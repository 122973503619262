import React, { Component, ReactNode } from "react"

import "./ErrorBoundary.sass"

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  error: Error | null
}

class RouteErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  override componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Error caught by RouteErrorBoundary:", error, errorInfo)
  }

  override render() {
    if (this.state.hasError) {
      const { name, message } = this.state.error || {}

      return (
        <div className="error">
          <div className="error__container">
            <div>
              <h4 className="error__name">{name}</h4>
            </div>
            <div>
              <p className="error__message">{message}</p>
            </div>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default RouteErrorBoundary
