import React, { ReactNode } from "react"

import classNames from "classnames"

import ConfirmActions from "../ConfirmActions"

import ArrowLeftVG from "../../assets/icons/arrow_left.svg"

import "./style.sass"

type AutoCloseProps = {
  isAutoCloseConfirm?: boolean
  onClose: () => void
  isAutoSubmitConfirm?: never
  onSubmit?: never
}

type AutoSubmitProps = {
  isAutoSubmitConfirm?: boolean
  onSubmit: () => void
  isAutoCloseConfirm?: never
  onClose?: () => void
}

type PropsBase = {
  ref?: React.RefObject<HTMLDivElement>
  title?: string | null
  children: ReactNode
  className?: string
  showFooter?: boolean
  isSubmitting?: boolean
}

type Props = PropsBase & (AutoCloseProps | AutoSubmitProps)

const Dialog = ({
  title,
  children,
  ref,
  className,
  isAutoCloseConfirm,
  onClose,
  isAutoSubmitConfirm,
  onSubmit,
  showFooter,
  isSubmitting,
}: Props) => {
  const dialogClassNames = classNames("dialog", className)

  return (
    <div className={dialogClassNames} ref={ref}>
      <div className="dialog__container">
        <div className="dialog__header">
          {title && <h2 className="dialog__title">{title}</h2>}

          {onClose && (
            <button
              className="dialog__close-btn"
              type="button"
              aria-label="Close"
              onClick={onClose}
            >
              <ArrowLeftVG />
            </button>
          )}
        </div>

        <div className="dialog__content">{children}</div>

        {showFooter && onClose && !onSubmit && (
          <div className="dialog__footer">
            <ConfirmActions
              isAutoCloseConfirm={isAutoCloseConfirm}
              onCloseConfirm={onClose}
            />
          </div>
        )}

        {showFooter && onSubmit && (
          <div className="dialog__footer">
            <ConfirmActions
              isAutoSubmitConfirm={isAutoSubmitConfirm}
              onSubmitConfirm={onSubmit}
              isSubmitting={isSubmitting}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Dialog
