import { createSlice, PayloadAction } from "@reduxjs/toolkit"

/**
 *  Slice
 */
export interface AppState {
  error: string | null
}

const initialState: AppState = {
  error: null,
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    appError: (state, { payload }: PayloadAction<string | null>) => {
      state.error = payload
    },
  },
})

export const appReducer = appSlice.reducer
export const { appError } = appSlice.actions
