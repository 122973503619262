import React, { ForwardedRef, Fragment, ReactNode } from "react"

import classNames from "classnames"

import { useNetworkStatus } from "../../../hooks/useNetworkStatus"
import { ISODate } from "../../../types/sharedTypes"
import { getTimeNow, isConfirmedEvent } from "../../../utils"
import MeetContentDialog from "./MeetContentDialog"
import { getTime, t, Trans } from "@joan/joan-core"

import { RoomProperties } from "../../../redux/constants/types"
import { Atendee, Person } from "../../../redux/events/types"
import { useAppSelector } from "../../../redux/store"

import Button from "../../../components/Button"
import Card from "../../../components/Card"
import Datestring from "../../../components/Datestring"
import Duration from "../../../components/Duration"
import Organizer from "../../../components/Organizer"
import Timestamp from "../../../components/Timestamp"

import MoreVerticalSVG from "../../../assets/icons/more-vertical.svg"

import "./NextMeetingCard.sass"

type MeetDialogProps = {
  title: string
  content: ReactNode
  className?: string
}

type Props = {
  id: string
  resource: string
  summary: string
  description: string
  organizer: Person
  roomProperties: RoomProperties
  attendees: Atendee[]
  start: ISODate
  end: ISODate
  forwardedRef: ForwardedRef<HTMLDivElement>
  showStatusBar: boolean
  canCheckIntoMeetings: boolean
  checkIntoMeetingsTimeout: number
  roomName: string
  canCancelMeetings: boolean
  onOpenMeetDetailsDialog: (meet: MeetDialogProps) => void
}

const NextMeetingCard = ({
  id,
  resource,
  summary,
  description,
  organizer,
  attendees = [],
  start,
  end,
  forwardedRef,
  checkIntoMeetingsTimeout,
  onOpenMeetDetailsDialog,
}: Props) => {
  const { confirmed: confirmedEventsIds } = useAppSelector(
    (state) => state.events,
  )

  const { isOnline } = useNetworkStatus()

  const isCheckInLoading = useAppSelector((state) => state.checkIn.isLoading)
  const isCancelMeetingLoading = useAppSelector(
    (state) => state.cancelMeeting.isLoading,
  )
  const isMeetLaterLoading = useAppSelector(
    (state) => state.meetLater.isLoading,
  )

  const now = getTimeNow()
  const isNextMeeting = start && end
  const startDate = getTime(start)
  const isMeetingInTheFuture = now.day() < startDate.day()

  const nextMeetingClassNames = classNames({
    NextMeetingCard: true,
    NoMeetingsCard: !isNextMeeting,
  })

  const areActionsLoading =
    isCheckInLoading || isCancelMeetingLoading || isMeetLaterLoading

  const minutesBeforeStart = startDate.diff(now, "minutes", true)

  const isConfirmable =
    !isConfirmedEvent(id, confirmedEventsIds) &&
    checkIntoMeetingsTimeout > minutesBeforeStart &&
    !areActionsLoading

  const handleOpenMeetDialog = () =>
    onOpenMeetDetailsDialog({
      title: summary,
      content: (
        <MeetContentDialog organizer={organizer} attendees={attendees} />
      ),
      className: "dialog--meet-next",
    })

  return (
    <Card forwardedRef={forwardedRef} className={nextMeetingClassNames}>
      <div className="container">
        {isNextMeeting ? (
          <>
            <div className="meeting-organizer-info">
              <h2 className="meeting-title">
                {summary || description || t("Booked")}
              </h2>
              <Organizer
                organizer={organizer}
                resource={resource}
                showIcon={false}
              />
            </div>

            <Duration>
              <div className="date">
                {isMeetingInTheFuture && (
                  <Datestring date={start} isInline isLongDay />
                )}
              </div>
              <div className="time">
                <Timestamp time={start} isInline /> &ndash;{" "}
                <Timestamp time={end} isInline />
              </div>
            </Duration>
          </>
        ) : (
          <Trans>No meetings scheduled for today.</Trans>
        )}

        {isNextMeeting && isOnline && (
          <div className="meeting-actions">
            <Button onClick={handleOpenMeetDialog} isIconButton>
              <MoreVerticalSVG />
            </Button>
          </div>
        )}
      </div>
    </Card>
  )
}

export default NextMeetingCard
