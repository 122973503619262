import React, { useCallback, useEffect, useState } from "react"

import { IS_DEMO_MODE } from "../../constants"
import { useDialog } from "../../hooks/useDialog"
import { canCancelMeeting, getTimeNow, isConfirmedEvent } from "../../utils"
import { getTime } from "@joan/joan-core"

import { finishMeetingDemo } from "../../redux/demoActions"
import { finishMeeting, recalculateEvents } from "../../redux/events/actions"
import {
  selectConfirmedEvents,
  selectEvents,
} from "../../redux/events/selectors"
import { ExtendedEvent } from "../../redux/events/types"
import { interfaceConfigSelector } from "../../redux/selectors"
import { useAppDispatch, useAppSelector } from "../../redux/store"

import Dialog from "../../components/Dialog"
import CurrentMeetingCard from "./components/CurrentMeetingCard"
import NextMeetingCard from "./components/NextMeetingCard"

import "./style.sass"

const Home = () => {
  const dispatch = useAppDispatch()

  const {
    constants,
    config,
    isFinishMeetLoading,
    events,
    confirmedEvents,
    isBookingDisabled,
  } = useAppSelector((state) => ({
    constants: state.constants,
    config: interfaceConfigSelector(state.constants),
    isFinishMeetLoading: state.finishMeeting.isLoading,
    events: selectEvents(state),
    confirmedEvents: selectConfirmedEvents(state),
    isBookingDisabled:
      state.constants.calendar &&
      state.constants.calendar.indexOf("http") === 0,
  }))

  const currentMeeting = events.currentEvent
  const nextMeeting = events.nextEvent

  const [selectedMeetId, setSelectedMeetId] = useState<string | null>(null)

  const recalcEventsIfNeeded = useCallback(() => {
    if (currentMeeting) {
      const now = getTimeNow()
      const endDate = getTime(currentMeeting.end)

      if (endDate.isBefore(now)) {
        dispatch(recalculateEvents())
      }
    }
  }, [currentMeeting, dispatch])

  const canCheckIntoMeetings = (event: ExtendedEvent) =>
    !!checkIntoMeetings &&
    !isBookingDisabled &&
    !isConfirmedEvent(event?.id, confirmedEvents)

  const { name = "", room_properties } = constants
  const { checkIntoMeetings } = config

  const {
    isOpenDialog: isOpenMeetDetailsDialog,
    title: meetDetailsTitle,
    content: meetDetailsContent,
    handleOpenDialog: handleOpenMeetDetailsDialog,
    handleCloseDialog: handleCloseMeetDetailsDialog,
    dialogRef: meetDetailsDialogRef,
    className: meetDetailsClassName,
  } = useDialog()

  const {
    isOpenDialog: isOpenMeetFinishDialog,
    content: meetFinishContent,
    handleOpenDialog: handleOpenMeetFinishDialog,
    handleCloseDialog: handleCloseMeetFinishDialog,
    dialogRef: meetFinishDialogRef,
    className: meetFinishClassName,
  } = useDialog()

  // TODO: Need to uncomment after adding logic to extend meeting time
  // const {
  //   isOpenDialog: isOpenMeetExtendDialog,
  //   content: meetExtendContent,
  //   handleOpenDialog: handleOpenMeetExtendDialog,
  //   handleCloseDialog: handleCloseMeetExtendDialog,
  //   dialogRef: meetExtendDialogRef,
  //   className: meetExtendClassName,
  // } = useDialog()

  const handleMeetFinishClick = (id: string) => setSelectedMeetId(id)

  const handleMeetFinishSubmit = useCallback(async () => {
    if (!selectedMeetId) return

    const response = await (IS_DEMO_MODE
      ? dispatch(finishMeetingDemo(selectedMeetId))
      : dispatch(finishMeeting(selectedMeetId)))

    if (response?.ok) {
      handleCloseMeetFinishDialog()
    }
  }, [dispatch, handleCloseMeetFinishDialog, selectedMeetId])

  // TODO: Need to uncomment after adding logic to extend meeting time
  // const handleMeetExtendSubmit = useCallback(async () => {
  //   if (!selectedMeetId) return
  //
  //   const response = await dispatch()
  //
  //   if (response?.ok) {
  //     handleCloseMeetExtendDialog()
  //   }
  // }, [dispatch, handleCloseMeetExtendDialog, selectedMeetId])

  useEffect(() => {
    recalcEventsIfNeeded()
    const intervalId = setInterval(recalcEventsIfNeeded, 1000)
    return () => clearInterval(intervalId)
  }, [recalcEventsIfNeeded])

  return (
    <div className="Home Route" key="home-route">
      <CurrentMeetingCard
        roomName={name}
        {...(currentMeeting || nextMeeting)}
        roomProperties={room_properties}
        canCancelMeetings={
          canCancelMeeting(config, currentMeeting) && !isBookingDisabled
        }
        canCheckIntoMeetings={canCheckIntoMeetings(
          currentMeeting || nextMeeting,
        )}
        checkIntoMeetingsTimeout={checkIntoMeetings}
        onOpenMeetDetailsDialog={handleOpenMeetDetailsDialog}
        onMeetFinishClick={handleMeetFinishClick}
        onOpenFinishMeetDialog={handleOpenMeetFinishDialog}
        showStatusBar
      />

      <NextMeetingCard
        {...nextMeeting}
        canCancelMeetings={
          canCancelMeeting(config, nextMeeting) && !isBookingDisabled
        }
        canCheckIntoMeetings={canCheckIntoMeetings(nextMeeting)}
        checkIntoMeetingsTimeout={checkIntoMeetings}
        onOpenMeetDetailsDialog={handleOpenMeetDetailsDialog}
        showControls
      />

      {isOpenMeetDetailsDialog && (
        <Dialog
          ref={meetDetailsDialogRef}
          title={meetDetailsTitle}
          className={meetDetailsClassName}
          onClose={handleCloseMeetDetailsDialog}
        >
          {meetDetailsContent}
        </Dialog>
      )}

      {isOpenMeetFinishDialog && (
        <Dialog
          ref={meetFinishDialogRef}
          onClose={handleCloseMeetFinishDialog}
          onSubmit={handleMeetFinishSubmit}
          className={meetFinishClassName}
          isSubmitting={isFinishMeetLoading}
          showFooter
        >
          {meetFinishContent}
        </Dialog>
      )}

      {/*// TODO: Need to uncomment after adding logic to extend meeting time*/}
      {/*{isOpenMeetExtendDialog && (*/}
      {/*  <Dialog*/}
      {/*    ref={meetExtendDialogRef}*/}
      {/*    content={meetExtendContent}*/}
      {/*    onClose={handleCloseMeetExtendDialog}*/}
      {/*    onSubmit={handleMeetExtendSubmit}*/}
      {/*    className={meetExtendClassName}*/}
      {/*    showFooter*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  )
}

export default Home
