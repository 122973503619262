import React, { useEffect, useState } from "react"

import classNames from "classnames"
import dayjs from "dayjs"
import { useLocation } from "react-router-dom"

import useOrientation from "../../hooks/useOrientation"
import Datestring from "../Datestring"
import Logo from "../Logo"
import Timestamp from "../Timestamp"

import "./style.sass"

type Props = {
  showDate: boolean
  showTime: boolean
  logo?: string
}

const Header = ({ showDate, showTime }: Props) => {
  const location = useLocation()
  const { pathname } = location || {}
  const { isLandscape } = useOrientation()

  const pathClass = `path-${pathname.substring(1, pathname.length)}`

  const headerClassName = classNames({
    Header: true,
    [pathClass]: true,
  })

  return (
    <div className={headerClassName}>
      {isLandscape && <Logo />}
      <DateTime showDate={showDate} showTime={showTime} />
    </div>
  )
}

type DateTimeProps = {
  showDate: boolean
  showTime: boolean
}

const DateTime = ({ showDate, showTime }: DateTimeProps) => {
  const [time, setTime] = useState(dayjs())

  useEffect(() => {
    const updateInterval = setInterval(() => {
      setTime(dayjs())
    }, 1000)

    return () => clearInterval(updateInterval)
  }, [])

  return (
    <div className="date-time">
      {showDate && <Datestring className="date" date={time} />}
      {showTime && <Timestamp className="time" time={time} leadingZero />}
    </div>
  )
}
export default Header
