import React, { ForwardedRef, ReactNode } from "react"

import classNames from "classnames"

type Props = {
  className?: string
  children?: ReactNode
  forwardedRef?: ForwardedRef<HTMLDivElement>
}

const Card = ({ className, children, forwardedRef, ...props }: Props) => {
  const cardClassNames = classNames({
    Card: true,
    [className || ""]: !!className,
  })

  return (
    <div className={cardClassNames} ref={forwardedRef} {...props}>
      {children}
    </div>
  )
}

export default Card
