import React, { useEffect } from "react"

import { Route, useHistory } from "react-router-dom"

import { PATHS } from "../constants"
import { useNetworkStatus } from "../hooks/useNetworkStatus"

import RouteErrorBoundary from "../components/ErrorBoundaries/RouteErrorBoundary"

type Props = {
  component: React.FC
  path: string
  exact?: boolean
}

const ErrorHandledRoute = ({ component: Component, path, exact }: Props) => {
  const history = useHistory()
  const { isOnline } = useNetworkStatus()

  useEffect(() => {
    if (!isOnline) {
      if (history.location.pathname !== PATHS.HOME) {
        history.push(PATHS.HOME)
      }
    }
  }, [isOnline, history])

  return (
    <Route
      path={path}
      exact={exact}
      render={() => (
        <RouteErrorBoundary>
          <Component />
        </RouteErrorBoundary>
      )}
    />
  )
}

export default ErrorHandledRoute
