import React from "react"

import dayjs from "dayjs"

import { ISODate } from "../../../types/sharedTypes"
import { nameOrEmail } from "../../../utils"
import { Trans } from "@joan/joan-core"

import { Person } from "../../../redux/events/types"

import "./EventContentDialog.sass"

type Props = {
  title: string
  organizer: Person
  start: ISODate
  end: ISODate
}

const CancelEventContentDialog = ({ title, organizer, start, end }: Props) => {
  return (
    <div className="dialog__body">
      <div className="dialog__section">
        <h5 className="dialog__label">
          <Trans>End meeting</Trans>
        </h5>
        <p className="dialog__value dialog__value--confirmation">
          <Trans>Are you sure you want to end the meeting?</Trans>
        </p>
      </div>

      <div className="dialog__section dialog__section--participant">
        <p className="dialog__value dialog__value--title">{title}</p>
        <p className="dialog__value dialog__value--organizer">
          {nameOrEmail(organizer)}
        </p>
        <p className="dialog__value dialog__value--date">
          {dayjs(start).format("LL")}
        </p>
        <p className="dialog__value dialog__value--duration">
          {dayjs(start).format("LT")} - {dayjs(end).format("LT")}
        </p>
      </div>
    </div>
  )
}

export default CancelEventContentDialog
