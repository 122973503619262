import React, { ReactNode } from "react"

import "./style.sass"

type Props = {
  children: ReactNode
}

const MeetingTitle = ({ children }: Props) => {
  return <h1 className="MeetingTitle">{children}</h1>
}

export default MeetingTitle
