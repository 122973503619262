import React from "react"

import { nameOrEmail } from "../../../utils"

import { Atendee, Person } from "../../../redux/events/types"

import "./MeetContentDialog.sass"

type Props = {
  organizer: Person
  attendees?: Atendee[]
  description?: string
}

const MeetContentDialog = ({ organizer, attendees, description }: Props) => {
  return (
    <div className="dialog__body">
      <div className="dialog__section dialog__section--participant">
        <h5 className="dialog__label">Organizer and Attendees:</h5>
        <p className="dialog__value dialog__value--organizer">
          {nameOrEmail(organizer)}
        </p>
        {!!attendees?.length && (
          <p className="dialog__value dialog__value--attendees">
            {attendees.map((attendee) => attendee.name)}
          </p>
        )}
      </div>
      {!!description && (
        <div className="dialog__section dialog__section--description">
          <h5 className="dialog__label">Description:</h5>
          <p className="dialog__value">{description}</p>
        </div>
      )}
    </div>
  )
}

export default MeetContentDialog
