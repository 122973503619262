import React, { Fragment, ReactNode } from "react"

import classNames from "classnames"
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch as RouterSwitch,
} from "react-router-dom"
import {
  LastLocationProvider,
  LastLocationType,
  useLastLocation,
} from "react-router-last-location"
import { CSSTransition, TransitionGroup } from "react-transition-group"

import Header from "./components/Header"
import Toolbar from "./components/Toolbar"

import "./AnimatedRouter.sass"

const TIMEOUT = 300

type AnimatedRouterProps = {
  children: ReactNode
}

const AnimatedRouter = ({ children }: AnimatedRouterProps) => {
  return (
    <Router>
      <LastLocationProvider>
        <Route
          render={({ location }) => {
            return (
              <Fragment>
                <Route exact path="/" render={() => <Redirect to="/home" />} />

                <Header showDate showTime />

                <DOMAnimatedRouter location={location}>
                  <TransitionGroup appear enter exit>
                    <CSSTransition
                      key={location.key}
                      timeout={TIMEOUT}
                      classNames="route"
                      unmountOnExit
                    >
                      <RouterSwitch location={location}>
                        {children}
                      </RouterSwitch>
                    </CSSTransition>
                  </TransitionGroup>
                </DOMAnimatedRouter>

                <Toolbar />
              </Fragment>
            )
          }}
        />
      </LastLocationProvider>
    </Router>
  )
}

export default AnimatedRouter

type DOMAnimatedRouterProps = {
  children: ReactNode
  location: LastLocationType
}

const DOMAnimatedRouter = ({ children, location }: DOMAnimatedRouterProps) => {
  const lastLocation = useLastLocation()

  const pathClass = location && `path-${location.pathname.slice(1)}`
  const lastPathClass =
    lastLocation && `prev-path-${lastLocation.pathname.slice(1)}`

  const animatedRouterClassNames = classNames({
    AnimatedRouter: true,
    [pathClass || ""]: !!pathClass,
    [lastPathClass || ""]: !!lastPathClass,
  })

  return <div className={animatedRouterClassNames}>{children}</div>
}
