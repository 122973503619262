import { ReactNode, useCallback, useRef, useState } from "react"

import { useClickOutside } from "./useClickOutside"

type DialogStateProps = {
  isOpen: boolean
  title: string | null
  content: ReactNode | null
  className: string
}

const INITIAL_DIALOG_STATE: DialogStateProps = {
  isOpen: false,
  title: null,
  content: null,
  className: "",
}

export const useDialog = () => {
  const [dialog, setDialog] = useState(INITIAL_DIALOG_STATE)

  const dialogRef = useRef<HTMLDivElement>(null)

  const { isOpen, title, content, className } = dialog

  const handleChangeDialogState = (
    newState: Partial<typeof INITIAL_DIALOG_STATE>,
  ) => setDialog((prevState) => ({ ...prevState, ...newState }))

  const handleOpenDialog = useCallback(
    (newState: Partial<typeof INITIAL_DIALOG_STATE>) => {
      handleChangeDialogState({ ...newState, isOpen: true })
    },
    [],
  )

  const handleCloseDialog = useCallback(() => {
    handleChangeDialogState({
      title: null,
      content: null,
      className: "",
      isOpen: false,
    })
  }, [])

  useClickOutside(dialogRef, () => {
    if (isOpen) {
      handleCloseDialog()
    }
  })

  return {
    isOpenDialog: isOpen,
    handleOpenDialog,
    handleCloseDialog,
    title,
    content,
    dialogRef,
    className,
  }
}
