import React, { useMemo } from "react"

import dayjs, { Dayjs } from "dayjs"

import { Trans } from "@joan/joan-core"

import { useAppSelector } from "../../../redux/store"

import ConfirmActions from "../../../components/ConfirmActions"

import "./InitialForm.sass"

type Props = {
  title: string
  date: Dayjs
  slot?: number | null
  duration?: number | null
  onOpenDateStep: () => void
  onOpenTimeslotStep: () => void
  onChangeTitle: (title: string) => void
  onSubmitConfirm: () => void
}

const InitialForm = ({
  title,
  date,
  slot,
  duration,
  onOpenDateStep,
  onOpenTimeslotStep,
  onChangeTitle,
  onSubmitConfirm,
}: Props) => {
  const { isMeetLaterLoading } = useAppSelector((state) => ({
    isMeetLaterLoading: state.meetLater.isLoading,
  }))

  const timeSlotFormatted = useMemo(() => {
    if (!slot || !duration) {
      return ""
    }

    const start = dayjs(slot)
    const end = start.add(duration, "minutes")

    return `${start.format("LT")} - ${end.format("LT")}`
  }, [duration, slot])

  const isDisabled = [title, date, slot, duration].some((item) => !item)

  return (
    <div className="form initial-form">
      <div className="form-control">
        <label htmlFor="date">
          <Trans>Date</Trans>
        </label>
        <input
          id="date"
          name="date"
          value={date.format("LL")}
          autoComplete="off"
          onClick={onOpenDateStep}
          readOnly
        />
      </div>

      <div className="form-control">
        <label htmlFor="timeslot">
          <Trans>Timeslot</Trans>
        </label>
        <input
          id="timeslot"
          name="timeslot"
          value={timeSlotFormatted}
          autoComplete="off"
          onClick={onOpenTimeslotStep}
          readOnly
        />
      </div>

      <div className="form-control">
        <label htmlFor="title">
          <Trans>Title</Trans>
        </label>
        <input
          id="title"
          name="title"
          value={title}
          autoComplete="off"
          onChange={(e) => onChangeTitle(e.currentTarget.value)}
        />
      </div>

      <ConfirmActions
        isAutoSubmitConfirm
        onSubmitConfirm={onSubmitConfirm}
        isDisabled={isDisabled || isMeetLaterLoading}
        isSubmitting={isMeetLaterLoading}
      />
    </div>
  )
}

export default InitialForm
