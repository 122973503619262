import { Dayjs } from "dayjs"

import { analyticsEvent, SUPPORTED_EVENTS } from "../../analytics"
import { fetchOptions } from "../../api/fetch"
import {
  bookURL,
  calendarURL,
  cancelMeetingURL,
  checkInURL,
  finishMeetingURL,
} from "../../api/urls"
import { filterOptimistic, getTimeNow, retryFetchUntil } from "../../utils"
import { AppDispatch, RootState } from "../store"
import {
  CANCEL_MEETING_ERROR,
  CANCEL_MEETING_START,
  CANCEL_MEETING_SUCCESS,
  CHECK_IN_ERROR,
  CHECK_IN_START,
  CHECK_IN_SUCCESS,
  FETCH_CALENDAR_ERROR,
  FETCH_CALENDAR_START,
  FETCH_CALENDAR_SUCCESS,
  FINISH_MEETING_ERROR,
  FINISH_MEETING_START,
  FINISH_MEETING_SUCCESS,
  MEET_LATER_ERROR,
  MEET_LATER_START,
  MEET_LATER_SUCCESS,
  MEET_NOW_DURATION,
  MEET_NOW_ERROR,
  MEET_NOW_START,
  MEET_NOW_SUCCESS,
} from "./constants"
import { EventResponse, MeetRequest, Person } from "./types"
import { getNextEventFromNow, getTime, t } from "@joan/joan-core"
import { DayJS } from "@joan/joan-core/dist/helpers/date.helpers"

// CALENDAR
export const fetchCalendarStart = (UUID: string) => ({
  type: FETCH_CALENDAR_START,
  payload: { UUID },
})

export const fetchCalendarSuccess = (
  events: EventResponse[],
  confirmed: string[],
) => ({
  type: FETCH_CALENDAR_SUCCESS,
  payload: { events, confirmed },
})

export const fetchCalendarError = (error: any) => ({
  type: FETCH_CALENDAR_ERROR,
  payload: error,
})

export const fetchCalendar =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { UUID },
    } = getState()

    try {
      dispatch(fetchCalendarStart(UUID))
      const response = await fetch(calendarURL(UUID), fetchOptions)

      if (response.status === 200) {
        const json = await response.json()

        if (!json.setup) {
          const { events: calendarEvents, confirmed } = json

          let events = []
          if (typeof calendarEvents === "string") {
            events = JSON.parse(calendarEvents)
          }

          dispatch(fetchCalendarSuccess(events, confirmed))
        } else {
          dispatch(fetchCalendarError(json))
        }
      } else {
        dispatch(fetchCalendarError(response.body))
      }
    } catch (e) {
      dispatch(fetchCalendarError(e))
    }
  }

// MEET NOW
export const meetNowStart = ({ start, end, summary }: MeetRequest) => ({
  type: MEET_NOW_START,
  payload: { start, end, summary },
})

export const meetNowSuccess = (json: {
  id?: string
  start: DayJS
  end: DayJS
  summary: string
  organizer: Person
}) => ({
  type: MEET_NOW_SUCCESS,
  payload: json,
})

export const meetNowError = (error: any) => ({
  type: MEET_NOW_ERROR,
  payload: error,
})

export const meetNow =
  (meetNowDuration = MEET_NOW_DURATION) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { UUID },
      events: { events },
    } = getState()

    const start = getTimeNow()
    let end = start.add(meetNowDuration, "minute")
    const nextMeeting = getNextEventFromNow(filterOptimistic(events))

    if (nextMeeting) {
      const nextStart = getTime(nextMeeting.start)
      if (nextStart.isBefore(end)) {
        end = nextStart
      }
    }

    const summary = t("Occupied")

    dispatch(
      meetNowStart({
        start: start.toISOString(),
        end: end.toISOString(),
        summary,
      }),
    )

    let resp
    try {
      resp = await fetch(
        bookURL(UUID, start.toISOString(), end.toISOString(), summary),
        fetchOptions,
      )

      if (resp.status === 200) {
        const json = await resp.json()
        dispatch(meetNowSuccess(json))
        analyticsEvent(SUPPORTED_EVENTS.ROOM_BOOKED, { id: json.id })
      } else {
        dispatch(meetNowError(resp.body))
      }
    } catch (e) {
      dispatch(meetNowError(e))
    }

    return resp
  }

// MEET LATER
export const meetLaterStart = ({ start, end, title }: MeetRequest) => ({
  type: MEET_LATER_START,
  payload: { start, end, title },
})

export const meetLaterSuccess = (json: {
  id: string
  start: DayJS
  end: DayJS
  summary: string
  organizer: Person
}) => ({
  type: MEET_LATER_SUCCESS,
  payload: json,
})

export const meetLaterError = (error: any) => ({
  type: MEET_LATER_ERROR,
  payload: error,
})

export const meetLater =
  (start: Dayjs, end: Dayjs, title: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { UUID },
    } = getState()

    const startParam = start.toISOString()
    const endParam = end.toISOString()

    let resp
    try {
      dispatch(meetLaterStart({ start, end, title }))
      resp = await fetch(
        bookURL(UUID, startParam, endParam, title),
        fetchOptions,
      )

      if (resp.status === 200) {
        const json = await resp.json()
        dispatch(meetLaterSuccess(json))
        analyticsEvent(SUPPORTED_EVENTS.ROOM_BOOKED, { id: json.id })

        return resp
      } else {
        dispatch(meetLaterError(resp.body))
        return resp
      }
    } catch (e) {
      dispatch(meetLaterError(e))
      return resp
    }
  }

// CHECK INTO MEETING
export const checkInStart = ({ id, end }: { id: string; end: Dayjs }) => ({
  type: CHECK_IN_START,
  payload: { id, end },
})

export const checkInSuccess = (json: EventResponse) => ({
  type: CHECK_IN_SUCCESS,
  payload: json,
})

export const checkInError = (error: any) => ({
  type: CHECK_IN_ERROR,
  payload: error,
})

export const checkIn =
  (id: string, end: Dayjs) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { csrftoken, UUID },
    } = getState()

    const start = getTimeNow().toISOString()

    let resp
    try {
      dispatch(checkInStart({ id, end }))

      resp = await fetch(checkInURL(UUID, id), {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({
          action: "checkinto",
          start,
          end,
        }),
      })

      if (resp.status === 200) {
        const json = await resp.json()
        dispatch(checkInSuccess(json))
        analyticsEvent(SUPPORTED_EVENTS.ROOM_BOOKING_CHECK_IN, { id: json.id })

        return resp
      } else {
        dispatch(checkInError(resp.body))
        return resp
      }
    } catch (e) {
      dispatch(checkInError(e))
      return resp
    }
  }

export const checkInAndFetchCalendar =
  (id: string, end: Dayjs) => async (dispatch: AppDispatch) => {
    try {
      await dispatch(checkIn(id, end))
      await dispatch(fetchCalendar())
    } catch (error) {
      console.error("Error during check-in and fetching calendar:", error)
      throw error
    }
  }

// FINISH MEETING
export const finishMeetingStart = (id: string) => ({
  type: FINISH_MEETING_START,
  payload: id,
})

export const finishMeetingSuccess = (json: { id: string }) => ({
  type: FINISH_MEETING_SUCCESS,
  payload: json,
})

export const finishMeetingError = (error: any) => ({
  type: FINISH_MEETING_ERROR,
  payload: error,
})

export const finishMeeting =
  (id: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { UUID },
    } = getState()

    try {
      dispatch(finishMeetingStart(id))

      const response = (await retryFetchUntil(
        finishMeetingURL(UUID, id),
        fetchOptions,
        { retryIn: [1000, 3000, 10000] },
      )) as { id: string }
      dispatch(finishMeetingSuccess(response))
      analyticsEvent(SUPPORTED_EVENTS.ROOM_BOOKING_END, { id: response.id })

      return { ok: true, response }
    } catch (e) {
      dispatch(finishMeetingError(e))

      return { ok: false, error: e }
    }
  }

// CANCEL MEETING
export const cancelMeetingStart = (id: string) => ({
  type: CANCEL_MEETING_START,
  payload: id,
})

export const cancelMeetingSuccess = (json: { id: string }) => ({
  type: CANCEL_MEETING_SUCCESS,
  payload: json,
})

export const cancelMeetingError = (error: any) => ({
  type: CANCEL_MEETING_ERROR,
  payload: error,
})

export const cancelMeeting =
  (id: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const {
      device: { UUID },
    } = getState()

    try {
      dispatch(cancelMeetingStart(id))

      const response = (await retryFetchUntil(
        cancelMeetingURL(UUID, id),
        fetchOptions,
        { retryIn: [1000, 3000, 10000] },
      )) as { id: string }

      dispatch(cancelMeetingSuccess(response))
      analyticsEvent(SUPPORTED_EVENTS.ROOM_BOOKING_CANCEL, { id: response.id })

      return { ok: true, response }
    } catch (e) {
      dispatch(cancelMeetingError(e))

      return { ok: false, error: e }
    }
  }

// RECALCULATE EVENTS
// Broadcast at end of event, etc.
export const recalculateEvents = () => ({
  type: "RECALCULATE_EVENTS",
})
