import React, { ReactNode } from "react"

import classNames from "classnames"
import { useHistory } from "react-router-dom"

import { PATHS } from "../../constants"

import ArrowLeftVG from "../../assets/icons/arrow_left.svg"
import CloseSVG from "../../assets/icons/close.svg"

import "./style.sass"

type Props = {
  title?: ReactNode
  isShowBackButton?: boolean
  isShowCloseButton?: boolean
}

const NavHeaderBar = ({
  title,
  isShowBackButton,
  isShowCloseButton,
}: Props) => {
  const history = useHistory()

  const handleClose = () => history.push(PATHS.HOME)
  const handleBack = () => history.push(PATHS.HOME)

  return (
    <div
      className={classNames("nav-header-bar", {
        "nav-header-bar--with-back-button": isShowBackButton,
      })}
    >
      {title && <h1 className="nav-header-bar__title">{title}</h1>}

      {isShowBackButton && (
        <div className="nav-header-bar__back-button" onClick={handleBack}>
          <ArrowLeftVG />
        </div>
      )}

      {isShowCloseButton && (
        <div className="nav-header-bar__close-button" onClick={handleClose}>
          <CloseSVG />
        </div>
      )}
    </div>
  )
}

export default NavHeaderBar
