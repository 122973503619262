import React, { ReactNode } from "react"

import "./style.sass"

type Props = {
  children: ReactNode
}

const Duration = ({ children }: Props) => (
  <div className="Duration">{children}</div>
)

export default Duration
