import React, { Fragment, ReactNode, useMemo } from "react"

import classNames from "classnames"

import { useNetworkStatus } from "../../../hooks/useNetworkStatus"
import useOrientation from "../../../hooks/useOrientation"
import { ISODate } from "../../../types/sharedTypes"
import { getTimeNow } from "../../../utils"
import CancelEventContentDialog from "../../Timetable/components/CancelEventContentDialog"
import ExtendEventContentDialog from "../../Timetable/components/ExtendEventContentDialog"
import MeetContentDialog from "./MeetContentDialog"
import { getTime, t } from "@joan/joan-core"

import { RoomProperties } from "../../../redux/constants/types"
import { checkInAndFetchCalendar } from "../../../redux/events/actions"
import { Atendee, Person } from "../../../redux/events/types"
import { useAppDispatch, useAppSelector } from "../../../redux/store"

import Attendees from "../../../components/Attendees"
import Button from "../../../components/Button"
import Card from "../../../components/Card"
import Datestring from "../../../components/Datestring"
import Duration from "../../../components/Duration"
import Logo from "../../../components/Logo"
import MeetingTitle from "../../../components/MeetingTitle"
import Organizer from "../../../components/Organizer"
import StatusBar from "../../../components/StatusBar"
import Timestamp from "../../../components/Timestamp"

import CheckSVG from "../../../assets/icons/check.svg"
import CloseSVG from "../../../assets/icons/close.svg"
import MoreVerticalSVG from "../../../assets/icons/more-vertical.svg"
import PeopleSVG from "../../../assets/icons/people_full.svg"
import WiFiOffSVG from "../../../assets/icons/wifi_off.svg"

import "./CurrentMeetingCard.sass"

type MeetDialogProps = {
  title: string
  content: ReactNode
  className?: string
}

type Props = {
  id: string
  resource: string
  summary: string
  organizer: Person
  roomProperties: RoomProperties[]
  attendees: Atendee[]
  start: ISODate
  end: ISODate
  showStatusBar: boolean
  canCheckIntoMeetings: boolean
  checkIntoMeetingsTimeout: number
  roomName: string
  canCancelMeetings: boolean
  onOpenMeetDetailsDialog: (meet: MeetDialogProps) => void
  onMeetFinishClick: (id: string) => void
  onOpenFinishMeetDialog: (meet: MeetDialogProps) => void
}

const CurrentMeetingCard = ({
  id,
  roomName,
  resource,
  summary,
  organizer,
  roomProperties = [],
  attendees = [],
  start,
  end,
  showStatusBar,
  canCheckIntoMeetings,
  checkIntoMeetingsTimeout,
  canCancelMeetings,
  onOpenMeetDetailsDialog,
  onMeetFinishClick,
  onOpenFinishMeetDialog,
}: Props) => {
  const dispatch = useAppDispatch()

  const { isPortrait, isLandscape } = useOrientation()

  const { isOffline, isOnline } = useNetworkStatus()

  const isCheckInLoading = useAppSelector((state) => state.checkIn.isLoading)
  const isFinishMeetingLoading = useAppSelector(
    (state) => state.finishMeeting.isLoading,
  )

  const isMeetNowLoading = useAppSelector((state) => state.meetNow.isLoading)

  const meetingAttendees = attendees.filter((a) => a.mail !== organizer.email)
  const now = getTimeNow()
  const startDate = getTime(start)
  const endDate = getTime(end)

  const isVacant = !(now.isAfter(startDate) && now.isBefore(endDate))
  const isNextMeeting = start && now.isBefore(startDate)
  const isNextMeetingInTheFuture = now.day() < endDate.day()
  const isNextMeetingTomorrow = now.day() + 1 === endDate.day()
  const isNextMeetingWithinWeek = now.day() + 6 > endDate.day()

  const areActionsLoading =
    isCheckInLoading || isFinishMeetingLoading || isMeetNowLoading

  const minutesAfterStart = now.diff(startDate, "minutes", true)

  const isConfirmable =
    checkIntoMeetingsTimeout > minutesAfterStart && !areActionsLoading

  const isCancellable = canCancelMeetings && !areActionsLoading

  const meetingTitle = useMemo(() => {
    if (isOffline) {
      return t("N/A")
    }

    if (!isVacant) {
      return summary || t("Occupied")
    }

    return t("Vacant")
  }, [isOffline, isVacant, summary])

  const currentMeetingClassNames = classNames({
    CurrentMeeting: true,
    OccupiedCard: !isVacant,
    VacantCard: isVacant,
  })

  const handleOpenMeetDialog = () =>
    onOpenMeetDetailsDialog({
      title: meetingTitle,
      content: (
        <MeetContentDialog organizer={organizer} attendees={attendees} />
      ),
      className: "dialog--meet-current",
    })

  const handleOpenFinishMeetDialog = (
    title: string,
    organizer: Person,
    start: ISODate,
    end: ISODate,
  ) => {
    onMeetFinishClick(id)
    onOpenFinishMeetDialog({
      title,
      content: (
        <CancelEventContentDialog
          title={title}
          organizer={organizer}
          start={start}
          end={end}
        />
      ),
      className: "dialog--cancel-event-confirmation",
    })
  }

  // TODO: Need to uncomment after adding logic to extend meeting time
  // const handleOpenExtendMeetDialog = (
  //   title: string,
  //   organizer: Person,
  //   start: ISODate,
  //   end: ISODate,
  // ) => {
  //   onMeetFinishClick(id)
  //   onOpenExtendMeetDialog({
  //     title,
  //     content: (
  //       <ExtendEventContentDialog
  //         title={title}
  //         organizer={organizer}
  //         start={start}
  //         end={end}
  //       />
  //     ),
  //     className: "dialog--cancel-event-confirmation",
  //   })
  // }

  return (
    <Card className={currentMeetingClassNames}>
      <div className="room-details">
        <div className="room-details-header">
          <div className="room-name">{roomName}</div>
          {!isVacant && isLandscape && (
            <Button
              onClick={handleOpenMeetDialog}
              className="MeetDetails"
              isIconButton
            >
              <MoreVerticalSVG />
            </Button>
          )}

          {isPortrait && <Logo variant="horizontal" />}
        </div>

        <div className="room-details-body">
          {isOffline && (
            <div className="room-details-item">
              <WiFiOffSVG />
              Offline
            </div>
          )}

          {!isOffline && (
            <div className="room-details-item">
              <PeopleSVG />
              Suitable for {roomProperties[0]?.capacity ?? 0} people
            </div>
          )}
        </div>
      </div>

      <div className="meeting-info">
        <MeetingTitle>{meetingTitle}</MeetingTitle>

        {!isVacant && isOnline && (
          <Organizer
            organizer={organizer}
            resource={resource}
            showIcon={false}
          />
        )}

        {!isVacant && isOnline && meetingAttendees.length > 0 && (
          <Attendees attendees={meetingAttendees} />
        )}
      </div>

      {(!isVacant || isNextMeeting) && isOnline && (
        <div className="meeting-actions">
          <Duration>
            <span>{t("Until")} </span>
            {isNextMeetingInTheFuture ? (
              <Fragment>
                <span> </span>
                {isNextMeetingTomorrow ? (
                  <span>{t("tomorrow")}</span>
                ) : isNextMeetingWithinWeek ? (
                  <span>{endDate.format("dddd")}</span>
                ) : (
                  <Datestring date={end} isInline />
                )}
                <span>, </span>
              </Fragment>
            ) : (
              <span> </span>
            )}
            <Timestamp time={isNextMeeting ? start : end} isInline />
          </Duration>

          {!isVacant && (
            <div className="actions">
              {canCheckIntoMeetings && (
                <Button
                  onClick={() => dispatch(checkInAndFetchCalendar(id, endDate))}
                  isDisabled={!isConfirmable}
                  isIconButton
                >
                  <CheckSVG />
                </Button>
              )}

              {/*TODO: Need to uncomment after adding logic to extend meeting time*/}
              {/*<Button*/}
              {/*  onClick={() => {*/}
              {/*    handleOpenExtendMeetDialog(*/}
              {/*      meetingTitle,*/}
              {/*      organizer,*/}
              {/*      start,*/}
              {/*      end,*/}
              {/*    )*/}
              {/*  }}*/}
              {/*  isIconButton*/}
              {/*>*/}
              {/*  <ExtendSVG />*/}
              {/*</Button>*/}

              {canCancelMeetings && (
                <Button
                  onClick={() => {
                    handleOpenFinishMeetDialog(
                      meetingTitle,
                      organizer,
                      start,
                      end,
                    )
                  }}
                  className="Confirm"
                  isDisabled={!isCancellable}
                  isIconButton
                >
                  <CloseSVG />
                </Button>
              )}

              {!isVacant && isPortrait && (
                <Button
                  onClick={handleOpenMeetDialog}
                  className="MeetDetails"
                  isIconButton
                >
                  <MoreVerticalSVG />
                </Button>
              )}
            </div>
          )}
        </div>
      )}

      {showStatusBar && (
        <StatusBar
          className="meeting-status"
          isTaken={!isVacant}
          start={start}
          end={end}
        />
      )}
    </Card>
  )
}

export default CurrentMeetingCard
